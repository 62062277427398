@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
} */

.dark {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

.custom-font-open-sanc {
  font-family: 'Open Sans', sans-serif !important;
}

.custom-font-public-sanc {
  font-family: 'Public Sans', sans-serif !important;
}

/* Additional styles */
.or-circle {
    display: inline-block;
    background-color: #010e4ef6;
    color: white;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    text-align: center;
}

.bg-custom-gradient {
  background: linear-gradient(93deg, rgba(24, 87, 136, 0.698) 0%, rgb(157, 194, 254) 100%);
}

/* Custom Padding Classes */
.px-custom-3 {
  padding-left: 0.75rem !important; /* 12px */
  padding-right: 0.75rem !important; /* 12px */
}

.py-custom-3 {
  padding-top: 0.75rem; /* 12px */
  padding-bottom: 0.75rem; /* 12px */
}



