.spinner {
    border: 4px solid grey;
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }