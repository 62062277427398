/* custom-scrollbar.css */

.custom-scrollbar {
    overflow-y: auto;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent; /* Fully transparent track */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: transparent; /* Fully transparent thumb */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: transparent; /* Remain transparent on hover */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:active {
    background: transparent; /* Remain transparent on active */
  }
  